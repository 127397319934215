import { NgModule } from '@angular/core';
import { GuiModule } from '../../gui/gui.module';
import { RouterModule } from '@angular/router';
import { GroupsComponent } from './components/groups/groups.component';
import { DynamicGridModule } from '../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { DynamicFilterModule } from '../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { ReportGridComponent } from './components/report-grid/report-grid.component';
import { ReportsWizardComponent } from './components/reports-wizard/reports-wizard.component';
import { TranslocoModule } from '@jsverse/transloco';
import { routes } from './reports.routes';

const ENTRY_MODULES = [GuiModule, DynamicGridModule, DynamicFilterModule];

const ENTRY_COMPONENTS = [ReportGridComponent, ReportsWizardComponent];

@NgModule({
  imports: [...ENTRY_MODULES, RouterModule.forChild(routes), TranslocoModule, GroupsComponent],
  declarations: [...ENTRY_COMPONENTS]
})
export class ReportsModule {}
